<template>
    <div class="editor">
        <div ref="toolbar" class="toolbar">
        </div>
        <!-- <div ref="editor" class="text">
        </div> -->
    </div>
</template>

<script>
    import E from 'wangeditor';
    export default {
        name: 'editoritem',
        data() {
            return {
                editor: null,
                info_: null
            }
        },
        props: {
            editorMain: {
                type: String,
                default: ''
            },
            mayEdit: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            //editorMain为编辑框输入的内容，这里我监听了一下值，当父组件调用得时候，如果给value赋值了，子组件将会显示父组件赋给的值
            editorMain: function(value) {
                if (value !== this.editor.txt.html()) {
                    this.editor.txt.html(this.editorMain)
                }
            }
        },

        mounted() {
            this.seteditor()
            this.editor.txt.html(this.editorMain)
        },
        methods: {
            seteditor() {
                this.editor = new E(this.$refs.toolbar, this.$refs.editor)
                this.editor.customConfig.uploadImgServer = this.domain + '/upload/uploadFile' // 配置服务器端地址
                this.editor.customConfig.uploadImgHeaders = {} // 自定义 header
                this.editor.customConfig.uploadFileName = 'pic' // 后端接受上传文件的参数名
                this.editor.customConfig.uploadImgMaxSize = 2 * 1024 * 1024 // 将图片大小限制为 2M
                this.editor.customConfig.uploadImgMaxLength = 1 // 限制一次最多上传 3 张图片
                this.editor.customConfig.uploadImgTimeout = 3 * 60 * 1000 // 设置超时时间
                
                // 配置菜单
                this.editor.customConfig.menus = [
                    // 菜单配置
                    'head', // 标题
                    'bold', // 粗体
                    'fontSize', // 字号
                    'fontName', // 字体
                    'italic', // 斜体
                    'foreColor', // 文字颜色
                    'backColor', // 背景颜色
                    'link', // 插入链接
                    // 'list', // 列表
                    'justify', // 对齐方式
                    'emoticon', // 表情
                    'image', // 插入图片
                    'table', // 表格
                    'undo', // 撤销
                ];
                //上传图片处理
                this.editor.customConfig.uploadImgHooks = {
                    fail: (xhr, editor, result) => {
                        // 插入图片失败回调
                        console.log(xhr + editor + result);
                    },
                    success: (xhr) => {
                        // 图片上传成功回调
                        console.log(xhr);
                    },
                    timeout: (xhr, editor) => {
                        // 网络超时的回调
                        console.log(xhr + editor);
                    },
                    error: (xhr, editor) => {
                        // 图片上传错误的回调
                        console.log(xhr);
                        console.log(editor);
                    },
                    customInsert: (insertImg, result) => {
                        // 图片上传成功，插入图片的回调
                        //result为上传图片成功的时候返回的数据，这里我打印了一下发现后台返回的是data：[{url:"路径的形式"},...]
                        //insertImg()为插入图片的函数
                        //循环插入图片
                        for (let i = 0; i < 1; i++) {
                            // let url = this.domain + result.data.path
                            let url = result.data.path
                            insertImg(url)
                        }
                    }
                }

                this.editor.customConfig.onchange = (html) => {
                    this.info_ = html // 绑定当前逐渐地值
                    this.$emit('change', this.info_) // 将内容同步到父组件中
                }
                // 创建富文本编辑器
                this.editor.create();

                //查看 不可编辑
                if (this.mayEdit) { 
                    this.editor.$textElem.attr('contenteditable', false)
                }
            }
        }
    }
</script>

<style lang="css">
    .editor {
        width: 100%;
        margin: 0 auto;
        position: relative;
        z-index: 0;
    }
    .w-e-toolbar {
        border: 1px solid #ececec !important;
        background: #f9f9f9 !important;
    }
    .w-e-text-container {
        border: 1px solid #ececec !important;
        border-top: 0 !important;
        min-height: 60vh !important;
    }
    
</style>
